<script src="../../router/index.js"></script>
<template>
  <div class="whiteList">
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="80px">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <el-form-item label="rule name" prop="name">
                <el-input
                  clearable
                  v-model.number="filterForm.name"
                  placeholder="Please enter rule name"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
              <el-form-item label-width="0" class="mb0">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search
                </el-button>
                <el-button type="primary" @click="openDialog('add')">Create</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width: 100%; min-height: 70vmin"
          stripe
          border
          :data="list"
          highlight-current-row
          aria-setsize="mini"
          size="mini"
          class="mb10"
        >
          <el-table-column label="id" prop="id" align="center"></el-table-column>
          <el-table-column label="rule name" prop="name" align="center"></el-table-column>
          <el-table-column
            label="check range(hour)"
            prop="timeRange"
            align="center"
          ></el-table-column>
          <el-table-column label="clicks" prop="clicks" align="center"></el-table-column>
          <el-table-column
            label="noInstallClicks"
            prop="noInstallClicks"
            align="center"
          ></el-table-column>
          <el-table-column label="rejects" prop="rejects" align="center"></el-table-column>
          <el-table-column label="installs" prop="installs" align="center"></el-table-column>
          <el-table-column label="reject rate" prop="rejectRate" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.rejectRate != null"> {{ scope.row.rejectRate }}% </span>
            </template>
          </el-table-column>
          <el-table-column label="create time" prop="createTime" align="center"></el-table-column>
          <el-table-column label="update time" prop="updateTime" align="center"></el-table-column>
          <el-table-column label="Action" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                size="mini"
                @click="openDialog('edit', scope.row)"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="deletes(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog
          :title="dialog.title"
          :visible.sync="dialog.visible"
          width="30%"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :show-close="false"
        >
          <el-form
            v-model="postData"
            size="small"
            :model="postData"
            ref="postDataForm"
            label-width="130px"
            :rules="rules"
            v-if="dialog.type !== 'off'"
          >
            <el-form-item label="name" prop="name">
              <el-input
                v-model="postData.name"
                placeholder="rule name"
                maxlength="20"
                type="text"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="check hour" prop="timeRange">
              <el-input
                v-model.number="postData.timeRange"
                placeholder="check hour"
                type="number"
              ></el-input>
            </el-form-item>
            <el-card>
              <div slot="header" class="clearfix">
                <span>data filter(clicks、rejects、installs条件都满足) 拒绝率过大的情况</span>
              </div>
              <el-form-item label="clicks >" prop="clicks">
                <el-input
                  v-model.number="postData.clicks"
                  placeholder="clicks"
                  type="number"
                ></el-input>
              </el-form-item>
              <el-form-item label="rejects >" prop="rejects">
                <el-input
                  v-model.number="postData.rejects"
                  placeholder="rejects"
                  type="number"
                ></el-input>
              </el-form-item>
              <el-form-item label="installs >" prop="installs">
                <el-input
                  v-model.number="postData.installs"
                  placeholder="installs"
                  type="number"
                ></el-input>
              </el-form-item>
              <el-form-item label="reject rate >" prop="rejectRate">
                <el-input
                  v-model="postData.rejectRate"
                  placeholder="reject rate(exclude cf)"
                  type="number"
                >
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-card>

            <el-card>
              <div slot="header" class="clearfix">
                <span>有点击没install的情况</span>
              </div>
              <el-form-item label="no install clicks >" prop="noInstallClicks">
                <el-input
                  v-model.number="postData.noInstallClicks"
                  placeholder="noInstallClicks"
                  type="number"
                ></el-input>
              </el-form-item>
            </el-card>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog('postDataForm')" size="mini">取 消</el-button>
            <el-button
              type="primary"
              size="mini"
              @click="submitDialog('postDataForm')"
              :loading="loading.submitBtn"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import {
    replaceAutoMixRuleAdd,
    replaceAutoMixRuleDelete,
    fetchReplaceAutoMixRulePage,
    replaceAutoMixRuleEdit,
  } from 'api/setting';
  import Pagination from '@/components/pagination';

  export default {
    components: {
      Pagination,
    },
    data() {
      return {
        filterForm: {
          page: 1,
          pageSize: 20,
        },
        total: 0,
        loading: {
          list: false,
        },
        list: [],
        postData: {
          name: '',
          logic: 'and',
        },
        options: {
          pidList: [],
        },
        dialog: {
          visible: false,
          title: '--',
          type: '',
        },
        rules: {
          // rejectRate: [
          //   { required: true, message: 'reject rate不能为空' },
          //   { type: 'number', message: 'reject rate必须为数字值' },
          // ],
          timeRange: [
            { required: true, message: 'timeRange不能为空' },
            { type: 'number', message: 'timeRange必须为数字值' },
          ],
          name: [{ required: true, message: 'rule name不能为空' }],
          clicks: [{ required: true, message: 'clicks不能为空' }],
          noInstallClicks: [{ required: true, message: 'noInstallClicks不能为空' }],
          rejectRate: [{ required: true, message: 'rejectRate不能为空' }],
        },
      };
    },
    mounted() {
      this.getList();
    },
    computed: {
      isAndChecked() {
        return this.postData.logic === 'and';
      },
    },
    methods: {
      getList(page) {
        if (page) {
          this.filterForm.page = page;
        }
        this.loading.list = true;
        fetchReplaceAutoMixRulePage(this.filterForm)
          .then((res) => {
            if (res.code == 200) {
              this.list = res.result;
              this.total = res.total;
            } else {
              this.$message.error(res.message);
            }
            this.loading.list = false;
          })
          .catch((e) => {
            this.loading.list = false;
            this.$message.error(e);
          });
      },

      // 打开弹窗
      openDialog(type, row) {
        this.dialog.type = type;
        this.dialog.title = type == 'add' ? '添加' : '编辑';
        this.dialog.visible = true;
        this.postData = {
          name: '',
          logic: 'and',
          replaceStatus: true,
        };
        // this.postData.logic = 'and';
        // this.postData.replaceStatus = 'true';
        if (row) {
          this.postData = Object.assign({}, row);
        }
      },

      // 删除
      deletes(id) {
        this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            replaceAutoMixRuleDelete(id).then((res) => {
              if (res.success === true) {
                this.$message.success('删除成功！');
                this.getList();
              } else {
                this.$message.error(res.message);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      // 提交弹窗
      submitDialog(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading.submitBtn = true;
            if (this.dialog.type == 'add') {
              replaceAutoMixRuleAdd(this.postData).then((res) => {
                if (res.success === true) {
                  this.$message.success(res.message);
                  this.loading.submitBtn = false;
                  this.closeDialog(formName);
                  this.getList();
                } else {
                  this.$message.error(res.message);
                  this.loading.submitBtn = false;
                }
              });
            } else if (this.dialog.type == 'edit') {
              replaceAutoMixRuleEdit(this.postData).then((res) => {
                if (res.success === true) {
                  this.$message.success(res.message);
                  this.loading.submitBtn = false;
                  this.closeDialog(formName);
                  this.getList();
                } else {
                  this.$message.error(res.message);
                  this.loading.submitBtn = false;
                }
              });
            }
          }
        });
      },
      // 关闭弹窗
      closeDialog(formName) {
        this.$refs[formName].resetFields();
        this.dialog.visible = false;
      },
      handleSizeChange(size) {
        this.filterForm.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.filterForm.page = currentPage;
        this.getList();
      },
    },
  };
</script>

<style></style>
